/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'message-outline': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M14.4-.001H1.6a1.6 1.6 0 00-1.6 1.6v14.4l3.2-3.2h11.2a1.6 1.6 0 001.6-1.6v-9.6a1.6 1.6 0 00-1.6-1.6zm0 11.2H3.2l-1.6 1.6v-11.2h12.8" _fill="#898B9B"/>'
  }
})
